import React, { useEffect, useState } from "react";
import { setBenefitGrid } from "../utils/Helpers/Grid";
import { getTimeStamp } from "../utils/Helpers/Functions";

const WelcomeUser = ({ name,benefitFocusPageToggle , isAuthenticated}: any) => {
  const [grid,setGrid] = useState("")
  useEffect(()=>{
    // setBenefitGrid(benefitFocusPageToggle,"col-12 col-sm-12 col-")
  },[])
  return (
    <div className="bg-color">
      <section className="row eva-back">
        <div className={`${benefitFocusPageToggle ? "col-lg-5" : "col-md-5 " } banner-text`}>
        { isAuthenticated && <h4>Welcome back, {name}</h4>}
          <p>
            {getTimeStamp()}
            {/* Thursday, April 27, 1:20pm ES 49°{" "} */}
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.00002 0.166687C8.25315 0.166687 8.46235 0.35479 8.49546 0.59884L8.50002 0.666687V2.00002C8.50002 2.27616 8.27616 2.50002 8.00002 2.50002C7.74689 2.50002 7.53769 2.31192 7.50458 2.06787L7.50002 2.00002V0.666687C7.50002 0.390545 7.72388 0.166687 8.00002 0.166687Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00845 2.83342L8.17734 2.83634C10.9557 2.93021 13.1667 5.21267 13.1667 8.00002C13.1667 10.8535 10.8535 13.1667 7.99159 13.1666L7.82271 13.1637C5.04438 13.0698 2.83335 10.7874 2.83342 7.99159L2.83634 7.82271C2.93021 5.04438 5.21267 2.83335 8.00002 2.83335L8.00845 2.83342ZM7.99602 3.83269L7.84741 3.8361C5.66949 3.91464 3.90973 5.66726 3.83599 7.84802L3.83335 8.00002C3.83335 10.2478 5.61654 12.0886 7.84802 12.1641L8.00002 12.1667C10.3012 12.1667 12.1667 10.3012 12.1667 8.00002C12.1667 5.75225 10.3835 3.91145 8.15202 3.83599L7.99602 3.83269Z"
                fill="black"
              />
              <path
                d="M8.00002 13.5C8.25315 13.5 8.46235 13.6881 8.49546 13.9322L8.50002 14V15.3334C8.50002 15.6095 8.27616 15.8334 8.00002 15.8334C7.74689 15.8334 7.53769 15.6453 7.50458 15.4012L7.50002 15.3334V14C7.50002 13.7239 7.72388 13.5 8.00002 13.5Z"
                fill="black"
              />
              <path
                d="M15.8334 8.00002C15.8334 7.72388 15.6095 7.50002 15.3334 7.50002H14L13.9322 7.50458C13.6881 7.53769 13.5 7.74689 13.5 8.00002C13.5 8.27616 13.7239 8.50002 14 8.50002H15.3334L15.4012 8.49546C15.6453 8.46235 15.8334 8.25315 15.8334 8.00002Z"
                fill="black"
              />
              <path
                d="M2.50002 8.00002C2.50002 7.72388 2.27616 7.50002 2.00002 7.50002H0.666687L0.59884 7.50458C0.35479 7.53769 0.166687 7.74689 0.166687 8.00002C0.166687 8.27616 0.390545 8.50002 0.666687 8.50002H2.00002L2.06787 8.49546C2.31192 8.46235 2.50002 8.25315 2.50002 8.00002Z"
                fill="black"
              />
              <path
                d="M3.40447 11.8891C3.59973 11.6939 3.91631 11.6939 4.11157 11.8891C4.28908 12.0666 4.30522 12.3444 4.15999 12.5402L4.11157 12.5962L3.16824 13.5396C2.97298 13.7348 2.6564 13.7348 2.46113 13.5396C2.28362 13.3621 2.26748 13.0843 2.41272 12.8885L2.46113 12.8325L3.40447 11.8891Z"
                fill="black"
              />
              <path
                d="M12.5402 11.8407C12.3444 11.6955 12.0666 11.7116 11.8891 11.8891C11.6939 12.0844 11.6939 12.401 11.8891 12.5962L12.8318 13.5389L12.8879 13.5873C13.0836 13.7326 13.3614 13.7164 13.5389 13.5389C13.7342 13.3436 13.7342 13.0271 13.5389 12.8318L12.5962 11.8891L12.5402 11.8407Z"
                fill="black"
              />
              <path
                d="M12.8325 2.4618C13.0277 2.26654 13.3443 2.26654 13.5396 2.4618C13.7171 2.63931 13.7332 2.91709 13.588 3.11283L13.5396 3.16891L12.5969 4.11157C12.4016 4.30684 12.0851 4.30684 11.8898 4.11157C11.7123 3.93406 11.6962 3.65629 11.8414 3.46055L11.8898 3.40447L12.8325 2.4618Z"
                fill="black"
              />
              <path
                d="M3.11216 2.41339C2.91642 2.26815 2.63864 2.28429 2.46113 2.4618C2.26587 2.65706 2.26587 2.97364 2.46113 3.16891L3.4038 4.11157L3.45988 4.15999C3.65562 4.30522 3.9334 4.28908 4.11091 4.11157C4.30617 3.91631 4.30617 3.59973 4.11091 3.40447L3.16824 2.4618L3.11216 2.41339Z"
                fill="black"
              />
            </svg>{" "} */}
          </p>
        </div>
        <div className={`${benefitFocusPageToggle ? "col-lg-7" : "col-md-7"}`}>
          <div className="row">
        <div className="col-md-4 benefit-cards">
          <div className="elipse-main">
            <h5 className="elipse">
              <span className="fw-bold">18</span>

              <img className="alrm-img" src="/assets/Alarm.png"></img>
            </h5>
            <h6 className="fw-bold text-height">
              Days left to<br></br> elect benefits
            </h6>
            <a className="elipse-anchor" href="#">
              Begin enrollment
            </a>
          </div>
        </div>
        <div className="col-md-4  benefit-cards divider" >
          <div className="elipse-main">
            <h5 className="elipse">
              <span className="fw-bold">4</span>
              <img className="alrm-img" src="/assets/Alarm.png"></img>
            </h5>
            <h6 className="fw-bold text-height">To-do items</h6>
            <a className="elipse-anchor" href="#">
              View to do list
            </a>
          </div>
          <div className="line" />
        </div>
        <div className="col-md-4 benefit-cards divider">
          <div className="elipse-main">
            <h5 className="elipse">
              <span className="fw-bold">12</span>
              {/* <img className="alrm-img" src="/assets/Alarm.png"></img> */}
            </h5>
            <h6 className="fw-bold text-height">Total benefits</h6>
            <a className="elipse-anchor" href="#">
              View benefits
            </a>
          </div>
          </div>
        </div>
        </div>
      </section>
    </div>
  );
};

export default WelcomeUser;
