import React from "react";

import { Wire_content_props } from "../Modules/modules";

const Wire_content = (props: Wire_content_props) => {
  return (
    <section className="wire-main-section">
      <div className="wire-content-div">
        <div className="wire-content">
          <h2> {props.heading}</h2>
          <p>{props.para}</p>
        </div>
        <div className="row g-4">
          <div
            className={`wire_cards ${
              props.isHeliosIframeOpen
                ? " col-md-6 col-lg-6 col-xxl-3"
                : "col-lg-3"
            } col-12 `}
          >
            <div className="card">
              <img
                src="/assets/wire_image.png"
                className="card-img-top"
                alt="wire_image"
              />
              <div className="card-main-content">
                <h5 className="card_heading">Start annual enrollment</h5>
                <p className="card-text-para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna.
                </p>
                <button className="card-btn">
                  Learn more
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_4436_1230)">
                      <path
                        d="M13.3416 9.16669H3.33325V10.8334H13.3416V13.3334L16.6666 10L13.3416 6.66669V9.16669Z"
                        fill="#2C2C2C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4436_1230">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`wire_cards ${
              props.isHeliosIframeOpen
                ? " col-md-6 col-lg-6 col-xxl-3"
                : "col-lg-3"
            } col-12 `}
          >
            <div className="card">
              <img
                src="/assets/wire_capules.png"
                className="card-img-top"
                alt="wire_image"
              />
              <div className="card-main-content">
                <h5 className="card_heading">Your lorem ipsum</h5>
                <p className="card-text-para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna.
                </p>
                <button className="card-btn">
                  Learn more{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_4436_1230)">
                      <path
                        d="M13.3416 9.16669H3.33325V10.8334H13.3416V13.3334L16.6666 10L13.3416 6.66669V9.16669Z"
                        fill="#2C2C2C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4436_1230">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`wire_cards ${
              props.isHeliosIframeOpen
                ? " col-md-6 col-lg-6 col-xxl-3"
                : "col-lg-3"
            } col-12 `}
          >
            <div className="card">
              <img
                src="/assets/wire_health.png"
                className="card-img-top"
                alt="wire_image"
              />
              <div className="card-main-content">
                <h5 className="card_heading">Stay Healthy. Be Rewarded</h5>
                <p className="card-text-para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna.
                </p>
                <button className="card-btn">
                  Learn more{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_4436_1230)">
                      <path
                        d="M13.3416 9.16669H3.33325V10.8334H13.3416V13.3334L16.6666 10L13.3416 6.66669V9.16669Z"
                        fill="#2C2C2C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4436_1230">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`wire_cards ${
              props.isHeliosIframeOpen
                ? " col-md-6 col-lg-6 col-xxl-3"
                : "col-lg-3"
            } col-12 `}
          >
            <div className="card">
              <img
                src="/assets/wire_glasses.png"
                className="card-img-top"
                alt="wire_image"
              />
              <div className="card-main-content">
                <h5 className="card_heading">Choose where you shop</h5>
                <p className="card-text-para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna.
                </p>
                <button className="card-btn">
                  Learn more
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="17"
                    viewBox="0 0 20 20"
                    fill="none"
                    className="link-svg"
                  >
                    <g clipPath="url(#clip0_4460_1256)">
                      <path
                        d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64167 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z"
                        fill="#2C2C2C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4460_1256">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wire_content;
