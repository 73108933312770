export const benefitsLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height="28"
      viewBox="0 0 161 28"
      fill="none"
    >
      <g filter="url(#filter0_d_897_1727)">
        <path
         fillRule="evenodd"
          clipRule="evenodd"
          d="M33.4798 21.586V12.6412C33.4798 9.00945 35.7022 6.11475 40.2783 6.11475C44.8547 6.11475 47.0771 9.00945 47.0771 12.6412V21.586H43.0371V12.4437C43.0371 10.9566 42.2621 9.5205 40.2783 9.5205C38.2948 9.5205 37.5194 10.9566 37.5194 12.4437V21.586H33.4798Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.1301 13.1497V18.1257H7.42343C9.08166 18.1257 10.016 17.0965 10.016 15.6091C10.016 14.0648 9.08166 13.1497 7.42343 13.1497H4.1301ZM4.1301 5.22829V9.77518H7.24287C8.7199 9.77518 9.56401 8.946 9.56401 7.48767C9.56401 6.02903 8.75021 5.22829 7.24287 5.22829H4.1301ZM8.02636 21.5859H0.000152588V1.79688H7.60431C11.7043 1.79688 13.8445 3.76996 13.8445 7.14418C13.8445 8.88849 12.9704 10.4618 11.6443 11.1479C13.3925 11.9486 14.3269 13.4072 14.3269 15.7807C14.3269 19.4125 11.7949 21.5859 8.02636 21.5859Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1543 12.292H27.4498C27.2689 10.5762 25.9122 9.26068 23.7116 9.26068C21.6616 9.26068 20.3655 10.5474 20.1543 12.292ZM20.064 15.1803C20.2749 17.2105 21.7822 18.4404 24.0734 18.4404C26.1234 18.4404 27.2989 17.4109 28.143 16.1526L31.1274 17.8681C30.1627 19.7843 27.9925 21.8437 24.0734 21.8437C19.1896 21.8437 15.8132 18.8408 15.8132 13.9792C15.8132 9.40352 19.069 6.0293 23.7419 6.0293C28.5954 6.0293 31.6401 9.0319 31.6401 13.6075C31.6401 13.9792 31.6401 14.5227 31.5798 15.1803H20.064Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.1065 12.292H60.402C60.2211 10.5762 58.8643 9.26068 56.6638 9.26068C54.6138 9.26068 53.3173 10.5474 53.1065 12.292ZM53.0159 15.1803C53.227 17.2105 54.7344 18.4404 57.0255 18.4404C59.0752 18.4404 60.2511 17.4109 61.0952 16.1526L64.0799 17.8681C63.1152 19.7843 60.9443 21.8437 57.0255 21.8437C52.1418 21.8437 48.7653 18.8408 48.7653 13.9792C48.7653 9.40352 52.0212 6.0293 56.6938 6.0293C61.5472 6.0293 64.5922 9.0319 64.5922 13.6075C64.5922 13.9792 64.5922 14.5227 64.5319 15.1803H53.0159Z"
          fill="black"
        />
        <mask
          id="mask0_897_1727"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="161"
          height="27"
        >
          <path d="M0 26.5882H160.838V0H0V26.5882Z" fill="white" />
        </mask>
        <g mask="url(#mask0_897_1727)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.0905 21.5858H79.1302V6.2865H75.0905V21.5858Z"
            fill="black"
          />
        </g>
        <mask
          id="mask1_897_1727"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="161"
          height="27"
        >
          <path d="M0 26.5882H160.838V0H0V26.5882Z" fill="white" />
        </mask>
        <g mask="url(#mask1_897_1727)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70.8332 5.62883C70.8332 4.73411 71.0955 3.92317 72.3751 3.88762V0.654687C72.1772 0.652832 72.0061 0.652832 71.9185 0.652832C68.0597 0.652832 66.7935 2.59748 66.7935 5.60008V6.28643H65.2865V9.51812H66.7935V21.5861H70.8332V9.51812H73.2149V6.28643H70.8332V5.62883Z"
            fill="black"
          />
        </g>
        <mask
          id="mask2_897_1727"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="161"
          height="27"
        >
          <path d="M0 26.5882H160.838V0H0V26.5882Z" fill="white" />
        </mask>
        <g mask="url(#mask2_897_1727)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M86.4742 6.28651H88.9462V9.5179H86.4742V16.6386C86.4742 17.7828 86.8659 18.2688 88.0118 18.2688H88.9462V21.5286C88.856 21.5286 88.1321 21.7287 86.7457 21.7287C83.7007 21.7287 82.4345 20.2703 82.4345 17.7253V9.5179H80.9272V6.28651H82.4345V4.31002L86.4742 2.94104V6.28651Z"
            fill="black"
          />
        </g>
        <mask
          id="mask3_897_1727"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="161"
          height="27"
        >
          <path d="M0 26.5882H160.838V0H0V26.5882Z" fill="white" />
        </mask>
        <g mask="url(#mask3_897_1727)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.0892 18.449C101.829 18.449 103.514 16.564 103.514 13.9655C103.514 11.3666 101.738 9.45351 99.0592 9.45351C96.3196 9.45351 94.6337 11.3666 94.6337 13.9655C94.6337 16.564 96.4096 18.449 99.0892 18.449ZM94.7543 26.588H90.7198V6.31209H94.5434V7.76857C95.3566 6.99751 96.9219 6.05518 99.4806 6.05518C104.357 6.05518 107.549 9.31068 107.549 13.9083C107.549 18.3349 104.418 21.8473 99.3001 21.8473C97.1628 21.8473 95.5668 21.019 94.7543 20.3621V26.588Z"
            fill="black"
          />
        </g>
        <mask
          id="mask4_897_1727"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="161"
          height="27"
        >
          <path d="M0 26.5882H160.838V0H0V26.5882Z" fill="white" />
        </mask>
        <g mask="url(#mask4_897_1727)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.994 21.5905H113.028V0.715088H108.994V21.5905Z"
            fill="black"
          />
        </g>
        <mask
          id="mask5_897_1727"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="161"
          height="27"
        >
          <path d="M0 26.5882H160.838V0H0V26.5882Z" fill="white" />
        </mask>
        <g mask="url(#mask5_897_1727)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M120.675 18.7636C119.289 18.7636 118.326 17.85 118.326 16.5651C118.326 15.3365 119.26 14.3936 120.643 14.3936C121.998 14.3936 122.992 15.2796 122.992 16.5651C122.992 17.85 121.998 18.7636 120.675 18.7636ZM120.825 6.14136C117.506 6.14136 115.686 7.60371 115.222 9.90296L118.902 10.5556C119.027 9.82938 119.543 9.16684 120.825 9.16684C122.33 9.16684 122.933 10.1098 122.933 11.4247V12.1951C122.51 11.9391 121.518 11.5675 120.072 11.5675C116.73 11.5675 114.531 13.7363 114.531 16.5924C114.531 19.7628 116.911 21.7891 120.132 21.7891C121.998 21.7891 123.083 21.0756 123.535 20.6198V21.59H126.906V11.9663C126.906 8.56861 125.46 6.14136 120.825 6.14136Z"
            fill="black"
          />
        </g>
        <mask
          id="mask6_897_1727"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="161"
          height="27"
        >
          <path d="M0 26.5882H160.838V0H0V26.5882Z" fill="white" />
        </mask>
        <g mask="url(#mask6_897_1727)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M140.032 17.1353L142.259 19.7054C141.296 20.6765 139.399 21.8473 136.63 21.8473C131.542 21.8473 128.291 18.4205 128.291 13.9942C128.291 9.53915 131.782 6.05518 136.6 6.05518C139.52 6.05518 141.296 7.16879 142.259 8.11143L139.941 10.7672C139.279 10.1674 138.225 9.51071 136.63 9.51071C133.981 9.51071 132.325 11.4238 132.325 13.9655C132.325 16.7068 134.342 18.3918 136.66 18.3918C138.286 18.3918 139.309 17.7067 140.032 17.1353Z"
            fill="black"
          />
        </g>
        <mask
          id="mask7_897_1727"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="161"
          height="27"
        >
          <path d="M0 26.5882H160.838V0H0V26.5882Z" fill="white" />
        </mask>
        <g mask="url(#mask7_897_1727)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M146.112 12.3093H153.398C153.218 10.5959 151.862 9.28224 149.665 9.28224C147.618 9.28224 146.323 10.5671 146.112 12.3093ZM146.022 15.1938C146.233 17.2213 147.738 18.449 150.026 18.449C152.073 18.449 153.247 17.421 154.09 16.1649L157.071 17.8779C156.107 19.7914 153.94 21.8476 150.026 21.8476C145.149 21.8476 141.777 18.849 141.777 13.9942C141.777 9.42507 145.029 6.05518 149.695 6.05518C154.542 6.05518 157.582 9.05376 157.582 13.6229C157.582 13.9942 157.582 14.5368 157.522 15.1938H146.022Z"
            fill="black"
          />
        </g>
        <mask
          id="mask8_897_1727"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="161"
          height="27"
        >
          <path d="M0 26.5882H160.838V0H0V26.5882Z" fill="white" />
        </mask>
        <g mask="url(#mask8_897_1727)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.6989 2.31645C74.6989 1.0294 75.7842 -0.00012207 77.1106 -0.00012207C78.4671 -0.00012207 79.5224 1.0294 79.5224 2.31645C79.5224 3.57444 78.4671 4.57551 77.1106 4.57551C75.7842 4.57551 74.6989 3.57444 74.6989 2.31645Z"
            fill="#65A6FF"
          />
        </g>
        <mask
          id="mask9_897_1727"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="161"
          height="27"
        >
          <path d="M0 26.5882H160.838V0H0V26.5882Z" fill="white" />
        </mask>
        <g mask="url(#mask9_897_1727)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.705 0H159.254L159.772 1.42958L160.289 0H160.838V1.97865H160.505V0.354921L159.92 1.97865H159.623L159.039 0.354921V1.97865H158.705V0ZM157.522 0.286905H157.015V0H158.368V0.286905H157.86V1.97865H157.522V0.286905Z"
            fill="#293A40"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_897_1727"
          x="0.000152588"
          y="0"
          width="160.838"
          height="27.588"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.933333 0 0 0 0 0.933333 0 0 0 0 0.933333 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_897_1727"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_897_1727"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
