import React from "react";

const MainContainer = () => {
  return (
    <div className=" bg-color">
      <section className="row banner">
        <div className="col-12 col-md-12 col-lg-3">
          <img className="space" src="/assets/hr-note-graphic.png"></img>
        </div>
        <div className="col-12 col-md-12 col-lg-8 banner-text">
          <h2 className="mb-0">Annual enrollment starts now</h2>
          <p>
            Annual enrollment runs November 15 - December 15. We’re offering 4
            new benefits! At Benefitfocus we are striving to be a place where
            our mission to improve lives with benefits starts with our
            associates. We want to offer a competitive and industry leading
            total rewards package that allows your total wellbeing to flourish
            through programs, products and...<button style={{color:"#0049F4",border:"none"}}>Read more</button>
          </p>
          <button className="get-started">Get started</button>
        </div>
      </section>
    </div>
  );
};

export default MainContainer;
