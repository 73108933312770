import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { useNavigate } from "react-router";

const SunnySdkPage = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { isLoading, error, loginWithRedirect, isAuthenticated, user } = useAuth0();
  let metaData: any;

  metaData = user && user["https://www.sunnyrewards.com/app_metadata"];

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    } else if (metaData?.consumerCode && user) {
      localStorage.setItem("isAuthenticated", "true");
      navigate("/home");
    }else{
       console.log("something went wrong")
    }
  }, [user, isLoading]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <>
      <div></div>
    </>
  );
};

export default SunnySdkPage;
