import React, { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "../Components/Navbar";
import Header from "../Components/Header";
import Wire_content from "../Components/Wire_content";
import HeliosSideBar from "../Components/HeliosSideBar";
import { commnicatesWithHeliosUi, execInit, extractSubdomain, openHomePageWidgetBasedOnEnv, userName } from "../utils/Helpers/Functions";

interface MetaData {
  consumerCode?: string;
}

const HomePage = () => {
  const { user, logout } = useAuth0();
  const modalBodyRef = useRef<HTMLDivElement>(null);
  const [currentPath,setCurrentPath] = useState<any>("")
  const [isIframeSiteOnHomePage,setIsIframeSiteOnHomePage] = useState({
    helpWidgetOpen:false,
    path:""
  })
  const [toggle, setToggle] = useState(false);
  const name = userName(user)
  let metaData: MetaData | undefined;
  metaData = user && user["https://www.sunnyrewards.com/app_metadata"];

  const signOut = () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
    localStorage.removeItem("isAuthenticated");
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };
  const url = window.location.hostname
  const subdomain = extractSubdomain(url);
  useEffect(() => {
    execInit(subdomain,"1158px",metaData);
    const iframe = document.getElementById("ifr1");
    const modalBody = modalBodyRef.current;
    if (iframe && modalBody) {
      modalBody.appendChild(iframe);
      setToggle(true);
    }
    return () => {
      if (iframe && modalBody) {
        modalBody.removeChild(iframe);
      }
    };
  }, [metaData]);
  useEffect(()=>{
    setCurrentPath(openHomePageWidgetBasedOnEnv(subdomain))
  },[subdomain,process.env])

  const handlePathChange = (data:any) => setIsIframeSiteOnHomePage({...data})
  
  useEffect(()=>{
    commnicatesWithHeliosUi(openHomePageWidgetBasedOnEnv(subdomain),handlePathChange)
  },[subdomain])

  return (
    <div className="main-wrapper banner-clicked">
           <div className="row full-width-mobile">
      <div className={`wrapper-div ${toggle ? "open" : "closed"}`}>
        <Navbar
          buttonText={"View your Rewards"}
          userWelcome={name?.split("@")[0]}
          handleSignInOrSignOut={signOut}
          signUpOrSignIn={"Log Out"}
          isHeliosIframeOpen={toggle}
          setToggle={setToggle}
          route={"/home"}
        />
        <Header
         handleSignInOrSignOut={handleToggle}
          heading={"Welcome Back, " + name?.split("@")[0]}
          para={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur."
          }
          flex_direction={"flex-row"}
          image={null}
          text={name?.split("@")[0] + ", you have $500 in rewards waiting"}
          isHeliosIframeOpen={toggle}
        />
        <Wire_content
          heading={`Lorem Ipsum Dolar`}
          para={
            "Depending on your plan benefits, you have a variety of ways to access your plan"
          }
          isHeliosIframeOpen={toggle}
        />
      </div>

      <HeliosSideBar
        handleToggle={handleToggle}
        toggle={toggle}
        modalBodyRef={modalBodyRef}
        isIframeSiteOnHomePage={isIframeSiteOnHomePage}
      />
      </div>
    </div>
  );
};

export default HomePage;
