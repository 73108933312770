import React, { useEffect } from "react";

import { HeaderProps } from "../Modules/modules";

const Header = (props: HeaderProps) => {
  return (
    <section className="main-header ">
      <div className={`row gx-0 ${props.flex_direction}`}>
        {props.image ? (
          <div className="left-image-content col-12 col-md-12 col-lg-6 ">
            <img src={props.image} alt="Wire Hero Image" />
          </div>
        ) : (
          <div className="home-page-image col-12 col-md-12 col-lg-6" />
        )}

        <div
          className="main-right-content-header_box col-12 col-md-12 col-lg-6"
          style={{ padding: props.isHeliosIframeOpen && "80px 64px 80px 72px" }}
        >
          <div className="content-box">
            <h2 dangerouslySetInnerHTML={{ __html: props.heading }} />
            <p>{props.para}</p>
            <button>learn more</button>
          </div>
        </div>
      </div>
      <div className="sign_to_see " onClick={()=>props.handleSignInOrSignOut()}>
        <p>
          {props.text}{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <g clipPath="url(#clip0_4674_518)">
              <path
                d="M13.8416 9.16675H3.83325V10.8334H13.8416V13.3334L17.1666 10.0001L13.8416 6.66675V9.16675Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_4674_518">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </p>
      </div>
    </section>
  );
};

export default Header;
