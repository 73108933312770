import BenefitsFocusHome from "../../Pages/BenefitsFocusHome";
import HomePage from "../../Pages/HomePage";
import LandingPage from "../../Pages/LandingPage";
import { sunnySDK } from "../Script/sunnySdk";
import { Route } from "react-router-dom";

export function extractSubdomain(url) {
  let domain = url.replace(/(^\w+:|^)\/\//, "");
  let subdomain = domain.split(".")[1];
  return subdomain;
}
export function userName(user) {
  return (
    user?.name && user?.name?.charAt(0)?.toUpperCase() + user?.name?.slice(1)
  );
}
const getHostname = () => window.location.hostname;
const hostname = getHostname();
let hostUrls =
  (process.env.REACT_APP_HOST_URLS &&
    JSON.parse(process.env.REACT_APP_HOST_URLS)) ||
  "";
const isHostNamematched = hostUrls && hostUrls.some((o) => o === hostname);
export const redirectOnLandingPageBasedOnHost = () => {
  if (isHostNamematched) {
    return <Route path="/" element={<LandingPage />} />;
  }
  return <Route path="/" element={<BenefitsFocusHome />} />;
};
export const redirectOnHomePageBasedOnHost = () => {
  if (isHostNamematched) {
    return <Route path="/home" element={<HomePage />} />;
  }
  return <Route path="/home" element={<BenefitsFocusHome />} />;
};

const handleSudoUrls = (subdomain) => {
  if (isHostNamematched) {
    return subdomain
      ? subdomain == "sunnyhealthplan"
        ? "prod"
        : subdomain
      : "dev";
  }
  return subdomain
    ? subdomain == "sunnybenefits"
      ? "prod"
      : subdomain
    : "dev";
};

export function execInit(subdomain, height, metaData) {
  let sdk = new sunnySDK();
  if (metaData?.consumerCode) {
    sdk.sdkSettings = {
      consumerCode: metaData?.consumerCode,
      // @ts-ignore
      width: "100%!important",
      // @ts-ignore
      height,
    };
  }
  sdk.sdkSettings.callbacks = {
    onClose: function () {
      var ifr = document.getElementById("ifr1");
      if (ifr) {
        ifr.remove();
      }
    },
  };

  sdk.sdkSettings.env = handleSudoUrls(subdomain);
  sdk.init();
}
export const openHomePageWidgetBasedOnEnv = (subdomain) => {
  if (subdomain == undefined || subdomain == "dev") {
    return process.env.REACT_APP_DEV_EVENT_ORIGIN;
  } else if (subdomain == "sunnyhealthplan") {
    return process.env.REACT_APP_EVENT_ORIGIN;
  } else if (subdomain == "qa") {
    return process.env.REACT_APP_QA_EVENT_ORIGIN;
  } else if (subdomain == "integ") {
    return process.env.REACT_APP_INTEG_EVENT_ORIGIN;
  }
};
export const getTimeStamp = () => {
  const now = new Date();
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour12: true,
    timeZone: "America/New_York",
  };

  let formattedDate = now.toLocaleDateString("en-US", options);
  let formattedTime = now.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "America/New_York",
  });
  const split = formattedTime.split(" ");
  const meridiemLowerCase = split[1].toLocaleLowerCase();
  const concat = split[0] + meridiemLowerCase;
  const formattedDateTime = `${formattedDate}, ${concat} EST`;
  return formattedDateTime;
};


export const commnicatesWithHeliosUi = (currentPath,callback) =>{
  window.addEventListener('message', function(event) {
    if(currentPath){
      if (event.origin != currentPath) return 
      if (event.data.type === 'PATH_CHANGE') {
        let data = JSON.parse(event.data.path)
         callback(data)
      }
    }
  })
}