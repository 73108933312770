import React, { useEffect, useState } from "react";
import { setBenefitGrid } from "../utils/Helpers/Grid";

const Services = ({ benefitFocusPageToggle }: any) => {
  const [grid, setGrid] = useState("");
  useEffect(() => {
    setGrid(
      setBenefitGrid(
        benefitFocusPageToggle,
        "col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3",
        "col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
      )
    );
  }, [benefitFocusPageToggle]);

  return (
    <div>
      <section className="quick-actions">
        <div className="container-fluid">
          <div className="row testing">
            <div className={`${grid}`}>
              <div className="card icon-smudge text-center d-flex align-items-center">
                <img
                  className="mr-4"
                  src="/assets/Smudge + Icon-3.svg"
                  alt="Icon"
                />
                <h4 className="fw-bold  text-start">
                  Start annual
                  <br />
                  enrollment.
                </h4>
              </div>
            </div>
            <div className={`${grid}`}>
              <div className="card icon-smudge text-center d-flex align-items-center">
                <img
                  className="mr-4"
                  src="/assets//Smudge + Icon.png"
                  alt="Icon"
                />
                <h4 className="fw-bold  text-start">
                  View your <br />
                  guide to AE
                </h4>
              </div>
            </div>
            <div className={`${grid}`}>
              <div className="card icon-smudge text-center d-flex align-items-center">
                <img
                  className="mr-4"
                  src="/assets/Smudge + Icon-5.svg"
                  alt="Icon"
                />
                <h4 className="fw-bold  text-start">
                  Edit current
                  <br />
                  benefits
                </h4>
              </div>
            </div>
            <div className={`${grid}`}>
              <div className="card icon-smudge text-center d-flex align-items-center">
                <img
                  className="mr-4"
                  src="/assets/Smudge + Icon-1.svg"
                  alt="Icon"
                />
                <h4 className="fw-bold  text-start">
                  View your <br />
                  total rewards
                </h4>
              </div>
            </div>
            <div className={`${grid}`}>
              <div className="card icon-smudge text-center d-flex align-items-center">
                <img
                  className="mr-4"
                  src="/assets/Smudge + Icon-2.svg"
                  alt="Icon"
                />

                <h4 className="fw-bold text-start">
                  Find quality
                  <br />
                  care
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
