import React from "react";

import { HeliosSideBarProps } from "../Modules/modules";

const HeliosSideBar = (props: HeliosSideBarProps) => {
  const {isIframeSiteOnHomePage,modalBodyRef,toggle} = props
  let currentPath = isIframeSiteOnHomePage?.path== "/home" || isIframeSiteOnHomePage?.path == "/see-all-action" 
  return (
    <div
      className={`helios_wrapper sidebar ${toggle ? "open" : "closed"}`}
      ref={modalBodyRef}
    >
      {currentPath &&
      !isIframeSiteOnHomePage.helpWidgetOpen ?
      <button className="minus-button" onClick={props.handleToggle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <mask
            id="mask0_4602_16509"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="25"
            height="24"
          >
            <rect x="0.467564" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_4602_16509)">
            <path d="M6.00003 14V12H18V14H6.00003Z" fill="#1C1B1F" />
          </g>
        </svg>
      </button> : null
       } 
    </div>
  );
};

export default HeliosSideBar;
