export const Demo_Logo_SVG = () => (
    <svg
      width="211"
      height="40"
      viewBox="0 0 211 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4891_7501)">
        <path
          d="M51.2673 10.0148H54.0988L60.8579 25.549H56.9956L55.6581 22.2607H49.6232L48.3314 25.549H44.5604L51.2738 10.0148H51.2673ZM52.5852 14.5361L50.6997 19.364H54.4968L52.5852 14.5361Z"
          fill="#7F8080"
        />
        <path
          d="M62.1954 10.0148H67.9889C68.6609 10.0148 69.3395 10.0604 70.018 10.1583C70.6965 10.2562 71.3098 10.4454 71.8513 10.7259C72.3928 11.013 72.8299 11.411 73.1692 11.9198C73.5019 12.4353 73.6715 13.1138 73.6715 13.9619C73.6715 14.8101 73.4302 15.5669 72.9408 16.1475C72.4515 16.7217 71.8056 17.1392 70.9966 17.3871V17.4328C71.512 17.5046 71.9752 17.6481 72.3928 17.8634C72.8103 18.0787 73.1692 18.3462 73.4693 18.6789C73.7694 19.0052 73.9978 19.3901 74.1609 19.8337C74.324 20.2709 74.4023 20.7406 74.4023 21.2364C74.4023 22.0585 74.2261 22.737 73.8738 23.285C73.5215 23.8331 73.0713 24.2767 72.5102 24.616C71.9557 24.9487 71.3294 25.1901 70.6313 25.3402C69.9332 25.4902 69.2481 25.562 68.5566 25.562H62.1954V10.0148ZM65.6141 16.2258H68.0933C68.3608 16.2258 68.6153 16.1932 68.8697 16.1345C69.1242 16.0758 69.359 15.9779 69.5613 15.8474C69.7635 15.7169 69.9266 15.5408 70.0571 15.319C70.1811 15.0971 70.2398 14.8362 70.2398 14.5295C70.2398 14.2229 70.168 13.9424 70.031 13.7271C69.894 13.5118 69.7113 13.3487 69.496 13.2312C69.2742 13.1138 69.0263 13.029 68.7523 12.9768C68.4717 12.9246 68.2042 12.8985 67.9368 12.8985H65.6141V16.2128V16.2258ZM65.6141 22.6587H68.687C68.948 22.6587 69.2155 22.6326 69.4895 22.5739C69.7635 22.5152 70.0049 22.4108 70.2268 22.2673C70.4486 22.1237 70.6247 21.928 70.7617 21.6931C70.8988 21.4583 70.9705 21.1777 70.9705 20.8385C70.9705 20.4731 70.8792 20.1795 70.6965 19.9512C70.5138 19.7293 70.2855 19.5532 70.0049 19.4358C69.7309 19.3183 69.4308 19.24 69.1046 19.1944C68.7849 19.1487 68.4848 19.1291 68.2042 19.1291H65.6141V22.6587Z"
          fill="#7F8080"
        />
        <path
          d="M86.348 14.0532C86.0153 13.6422 85.5978 13.3291 85.1084 13.1072C84.6191 12.8854 84.0515 12.7745 83.4056 12.7745C82.7597 12.7745 82.166 12.8985 81.618 13.1464C81.0699 13.3943 80.5937 13.7401 80.1892 14.1902C79.7847 14.6339 79.4715 15.1689 79.2432 15.7822C79.0148 16.3954 78.9039 17.0609 78.9039 17.7786C78.9039 18.4962 79.0213 19.1813 79.2432 19.788C79.4715 20.3948 79.7781 20.9233 80.1761 21.3669C80.5741 21.8105 81.0373 22.1629 81.5658 22.4108C82.0942 22.6587 82.6749 22.7827 83.2882 22.7827C83.9928 22.7827 84.6126 22.6391 85.1541 22.3455C85.6956 22.0519 86.1393 21.6409 86.4916 21.119L89.3427 23.2459C88.6837 24.1658 87.8486 24.8508 86.8439 25.288C85.8326 25.7251 84.7953 25.9469 83.7253 25.9469C82.5118 25.9469 81.3896 25.7577 80.3653 25.3793C79.341 25.0009 78.4537 24.4529 77.71 23.7417C76.9662 23.0306 76.379 22.1759 75.968 21.1646C75.5504 20.1534 75.3417 19.0312 75.3417 17.7851C75.3417 16.539 75.5504 15.4168 75.968 14.4055C76.3856 13.3943 76.9662 12.5331 77.71 11.8285C78.4537 11.1239 79.341 10.5758 80.3653 10.1909C81.3896 9.81249 82.5053 9.62329 83.7253 9.62329C84.1624 9.62329 84.6191 9.66244 85.0954 9.74073C85.5717 9.82554 86.0414 9.9495 86.4981 10.1257C86.9613 10.3018 87.3984 10.5367 87.829 10.8303C88.2531 11.1239 88.6315 11.4827 88.9708 11.9068L86.335 14.0598L86.348 14.0532Z"
          fill="#7F8080"
        />
        <path
          d="M97.2761 10.0148H100.701V15.8083H107.35V10.0148H110.775V25.549H107.35V18.8355H100.701V25.549H97.2761V10.0148Z"
          fill="#7F8080"
        />
        <path
          d="M123.386 23.7091C122.858 24.3811 122.192 24.903 121.39 25.2684C120.587 25.6338 119.752 25.8164 118.891 25.8164C118.03 25.8164 117.299 25.686 116.575 25.425C115.851 25.164 115.224 24.7856 114.689 24.2963C114.154 23.807 113.737 23.2198 113.43 22.5282C113.124 21.8367 112.967 21.0733 112.967 20.2252C112.967 19.377 113.124 18.6072 113.43 17.9221C113.737 17.2371 114.154 16.6434 114.689 16.1541C115.224 15.6647 115.851 15.2863 116.575 15.0254C117.299 14.7579 118.069 14.6339 118.891 14.6339C119.654 14.6339 120.346 14.7644 120.966 15.0254C121.585 15.2863 122.114 15.6647 122.544 16.1541C122.975 16.6434 123.308 17.2306 123.543 17.9221C123.778 18.6137 123.895 19.377 123.895 20.2252V21.256H116.262C116.392 21.8823 116.679 22.3847 117.116 22.7566C117.553 23.1285 118.095 23.3177 118.741 23.3177C119.282 23.3177 119.739 23.1937 120.111 22.9523C120.483 22.7109 120.809 22.3977 121.09 22.0193L123.393 23.7091H123.386ZM120.6 19.0117C120.613 18.4571 120.431 17.9808 120.052 17.5829C119.674 17.1914 119.178 16.9892 118.584 16.9892C118.219 16.9892 117.899 17.0479 117.619 17.1653C117.338 17.2827 117.103 17.4328 116.908 17.6155C116.712 17.7982 116.555 18.0069 116.444 18.2483C116.333 18.4897 116.268 18.7442 116.255 19.0051H120.6V19.0117Z"
          fill="#7F8080"
        />
        <path
          d="M132.363 24.2115H132.318C131.952 24.7856 131.463 25.1901 130.856 25.4446C130.25 25.6925 129.61 25.8164 128.938 25.8164C128.442 25.8164 127.96 25.7447 127.503 25.6077C127.04 25.4707 126.635 25.2619 126.283 24.9813C125.931 24.7008 125.657 24.355 125.448 23.9505C125.246 23.5395 125.141 23.0632 125.141 22.5217C125.141 21.9084 125.259 21.3865 125.48 20.9624C125.702 20.5383 126.015 20.186 126.4 19.912C126.785 19.638 127.229 19.4162 127.725 19.2661C128.221 19.1095 128.736 18.9986 129.271 18.9269C129.806 18.8551 130.341 18.8094 130.876 18.7964C131.411 18.7833 131.9 18.7768 132.357 18.7768C132.357 18.1896 132.148 17.7264 131.731 17.3871C131.313 17.0414 130.817 16.8717 130.25 16.8717C129.708 16.8717 129.219 16.9892 128.769 17.211C128.325 17.4393 127.927 17.746 127.575 18.1439L125.82 16.3433C126.433 15.7757 127.151 15.3451 127.973 15.058C128.788 14.7709 129.636 14.6339 130.517 14.6339C131.483 14.6339 132.279 14.7513 132.898 14.9927C133.518 15.2341 134.021 15.5864 134.392 16.0562C134.764 16.5259 135.025 17.1001 135.169 17.7786C135.312 18.4571 135.391 19.2465 135.391 20.1404V25.562H132.363V24.2245V24.2115ZM131.548 20.8776C131.3 20.8776 130.987 20.8906 130.615 20.9102C130.243 20.9298 129.884 20.995 129.538 21.0994C129.193 21.2038 128.906 21.3539 128.664 21.5626C128.423 21.7649 128.299 22.052 128.299 22.4173C128.299 22.8088 128.468 23.1024 128.801 23.2916C129.14 23.4808 129.486 23.5786 129.852 23.5786C130.171 23.5786 130.484 23.533 130.785 23.4481C131.085 23.3633 131.352 23.2394 131.587 23.0763C131.822 22.9132 132.005 22.7109 132.148 22.463C132.285 22.2151 132.357 21.9215 132.357 21.5887V20.8841H131.541L131.548 20.8776Z"
          fill="#7F8080"
        />
        <path
          d="M137.276 8.96436H140.564V25.5555H137.276V8.96436Z"
          fill="#7F8080"
        />
        <path
          d="M149.979 17.5241H147.082V21.0798C147.082 21.3734 147.095 21.6409 147.128 21.8823C147.16 22.1237 147.226 22.3325 147.323 22.5086C147.428 22.6848 147.584 22.8218 147.793 22.9131C148.008 23.011 148.289 23.0566 148.635 23.0566C148.811 23.0566 149.039 23.0371 149.326 23.0045C149.613 22.9718 149.829 22.887 149.972 22.75V25.4902C149.607 25.6207 149.229 25.712 148.831 25.7577C148.433 25.8033 148.048 25.8229 147.669 25.8229C147.115 25.8229 146.599 25.7642 146.136 25.6468C145.666 25.5293 145.262 25.3401 144.909 25.0857C144.557 24.8312 144.283 24.4985 144.087 24.0875C143.892 23.6764 143.794 23.1806 143.794 22.5934V17.5241H141.687V14.8883H143.794V11.7306H147.089V14.8883H149.985V17.5241H149.979Z"
          fill="#7F8080"
        />
        <path
          d="M154.722 8.96436V16.3367H154.768C154.839 16.1345 154.963 15.9257 155.14 15.7235C155.316 15.5212 155.524 15.3385 155.772 15.1754C156.02 15.0123 156.314 14.8818 156.653 14.784C156.986 14.6796 157.358 14.6274 157.749 14.6274C158.584 14.6274 159.256 14.7513 159.772 15.0123C160.281 15.2668 160.685 15.6256 160.966 16.0758C161.253 16.5325 161.448 17.0609 161.546 17.6807C161.644 18.3005 161.703 18.9595 161.703 19.6771V25.5555H158.415V20.3296C158.415 20.0229 158.402 19.7032 158.382 19.377C158.363 19.0508 158.297 18.7442 158.186 18.4636C158.075 18.1831 157.906 17.9613 157.684 17.7851C157.462 17.609 157.129 17.5241 156.705 17.5241C156.281 17.5241 155.936 17.6024 155.675 17.7525C155.407 17.9091 155.211 18.1113 155.068 18.3658C154.931 18.6202 154.839 18.9073 154.794 19.2335C154.748 19.5532 154.729 19.8924 154.729 20.2447V25.5555H151.44V8.96436H154.729H154.722Z"
          fill="#7F8080"
        />
        <path
          d="M170.498 10.0148H176.265C177.067 10.0148 177.831 10.0865 178.548 10.2366C179.266 10.3801 179.892 10.6346 180.427 10.9934C180.962 11.3522 181.387 11.835 181.7 12.4418C182.013 13.0485 182.169 13.8119 182.169 14.7318C182.169 15.6517 182.026 16.402 181.732 17.0153C181.439 17.6285 181.041 18.1179 180.525 18.4832C180.016 18.8486 179.41 19.1095 178.705 19.2596C178 19.4162 177.244 19.4945 176.422 19.4945H173.923V25.549H170.498V10.0148ZM173.916 16.5977H176.2C176.506 16.5977 176.806 16.5716 177.087 16.5129C177.374 16.4542 177.629 16.3563 177.857 16.2193C178.085 16.0823 178.268 15.8931 178.405 15.6517C178.542 15.4103 178.614 15.1102 178.614 14.7383C178.614 14.3469 178.522 14.0272 178.34 13.7858C178.157 13.5444 177.922 13.3617 177.635 13.2247C177.348 13.0942 177.035 13.0094 176.683 12.9702C176.33 12.9311 175.997 12.9181 175.671 12.9181H173.916V16.6042V16.5977Z"
          fill="#7F8080"
        />
        <path
          d="M183.729 8.96436H187.017V25.5555H183.729V8.96436Z"
          fill="#7F8080"
        />
        <path
          d="M195.857 24.2115H195.812C195.446 24.7856 194.957 25.1901 194.35 25.4446C193.743 25.6925 193.104 25.8164 192.432 25.8164C191.936 25.8164 191.453 25.7447 190.997 25.6077C190.533 25.4707 190.129 25.2619 189.777 24.9813C189.424 24.7008 189.15 24.355 188.942 23.9505C188.739 23.5395 188.635 23.0632 188.635 22.5217C188.635 21.9084 188.752 21.3865 188.974 20.9624C189.196 20.5383 189.509 20.186 189.894 19.912C190.279 19.638 190.723 19.4162 191.218 19.2661C191.714 19.1095 192.23 18.9986 192.765 18.9269C193.3 18.8551 193.835 18.8094 194.37 18.7964C194.905 18.7833 195.394 18.7768 195.851 18.7768C195.851 18.1896 195.642 17.7264 195.224 17.3871C194.807 17.0414 194.311 16.8717 193.743 16.8717C193.202 16.8717 192.713 16.9892 192.262 17.211C191.819 17.4393 191.421 17.746 191.068 18.1439L189.313 16.3433C189.927 15.7757 190.644 15.3451 191.466 15.058C192.282 14.7709 193.13 14.6339 194.011 14.6339C194.976 14.6339 195.772 14.7513 196.392 14.9927C197.012 15.2341 197.514 15.5864 197.886 16.0562C198.258 16.5259 198.519 17.1001 198.663 17.7786C198.806 18.4571 198.884 19.2465 198.884 20.1404V25.562H195.857V24.2245V24.2115ZM195.048 20.8776C194.8 20.8776 194.487 20.8906 194.115 20.9102C193.743 20.9298 193.385 20.995 193.039 21.0994C192.693 21.2038 192.406 21.3539 192.164 21.5626C191.923 21.7649 191.799 22.052 191.799 22.4173C191.799 22.8088 191.969 23.1024 192.301 23.2916C192.641 23.4808 192.987 23.5786 193.352 23.5786C193.672 23.5786 193.985 23.533 194.285 23.4481C194.585 23.3633 194.852 23.2394 195.087 23.0763C195.322 22.9132 195.505 22.7109 195.648 22.463C195.785 22.2151 195.857 21.9215 195.857 21.5887V20.8841H195.042L195.048 20.8776Z"
          fill="#7F8080"
        />
        <path
          d="M200.731 14.8884H203.889V16.3368H203.934C204.039 16.1345 204.182 15.9258 204.371 15.7235C204.561 15.5213 204.789 15.3386 205.05 15.1755C205.311 15.0124 205.611 14.8819 205.95 14.784C206.283 14.6796 206.655 14.6274 207.046 14.6274C207.881 14.6274 208.553 14.7514 209.069 15.0124C209.578 15.2668 209.982 15.6256 210.263 16.0758C210.55 16.5325 210.746 17.061 210.843 17.6808C210.941 18.3006 211 18.9595 211 19.6772V25.5555H207.712V20.3296C207.712 20.023 207.699 19.7033 207.679 19.3771C207.66 19.0509 207.594 18.7442 207.483 18.4637C207.372 18.1831 207.203 17.9613 206.981 17.7852C206.759 17.609 206.426 17.5242 206.002 17.5242C205.578 17.5242 205.233 17.6025 204.972 17.7525C204.704 17.9091 204.508 18.1114 204.365 18.3658C204.228 18.6203 204.136 18.9073 204.091 19.2335C204.045 19.5532 204.026 19.8925 204.026 20.2448V25.5555H200.737V14.8949L200.731 14.8884Z"
          fill="#7F8080"
        />
        <path
          d="M19.129 39.1323C29.6937 39.1323 38.2581 30.568 38.2581 20.0033C38.2581 9.43863 29.6937 0.874268 19.129 0.874268C8.56436 0.874268 0 9.43863 0 20.0033C0 30.568 8.56436 39.1323 19.129 39.1323Z"
          fill="#22ADE3"
        />
        <path
          d="M33.7889 18.0917H23.7416L30.8465 10.9868L28.1455 8.27922L21.0406 15.3841V5.33679H17.2174V15.3841L10.1125 8.27922L7.40495 10.9868L14.5098 18.0917H4.46252V21.9149H14.5098L7.40495 29.0197L10.1125 31.7208L17.2174 24.6159V34.6632H21.0406V24.6159L28.1455 31.7208L30.8465 29.0197L23.7416 21.9149H33.7889V18.0917Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4891_7501">
          <rect
            width="211"
            height="38.2515"
            fill="white"
            transform="translate(0 0.874268)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  