import React, { useState } from "react";
import { Link } from "react-router-dom";
import { benefitsLogo } from "../utils/Helpers/SvgImages/BenefitLogo";
import { useAuth0 } from "@auth0/auth0-react";

const BenefitsNav = ({ setBenefitFocusPageToggle, name }: any) => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const [hideMobileNav, setHideMobileNav] = useState(true);
  const handleBenefitAuth = () => {
    if (isAuthenticated && name) {
      setBenefitFocusPageToggle(true);
    } else {
      loginWithRedirect();
    }
  };
  const userlogout = () => {
    localStorage.removeItem("isAuthenticated");
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };
  return (
    <section className="bg-color benefitFocus">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container-fluid ">
          <Link className="navbar-brand" to="/">
            {benefitsLogo()}
          </Link>

          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setHideMobileNav(false)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`benefit_navbar_content set  benefitheliosIframeExtent ${
              hideMobileNav ? "hideMobileNav" : "showMobileNav"
            }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
              <li className="nav-item benefits-nav-links">
                <a className="nav-link" aria-current="page" href="#">
                  Profile
                </a>
              </li>
              <li className="nav-item benefits-nav-links">
                <a className="nav-link" href="#">
                  Benefits
                </a>
              </li>
              <li className="nav-item benefits-nav-links">
                <a className="nav-link" href="#">
                  Quick links
                </a>
              </li>
              <li className="nav-item benefits-nav-links">
                <a className="nav-link" href="#">
                  Company Info
                </a>
              </li>
              <li className="nav-item dropdown benefits-nav-links">
                <button className="header-btn" onClick={handleBenefitAuth}>
                  {isAuthenticated ? name : "Login"}
                  {isAuthenticated ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        className="dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.59999 4.799C9.59999 4.86129 9.57608 4.91692 9.52826 4.96475L6.16626 8.32764C6.11788 8.37603 6.06283 8.39995 5.99999 8.39995C5.93771 8.39995 5.88211 8.37603 5.83429 8.32764L2.47228 4.96475C2.4239 4.91692 2.39999 4.86129 2.39999 4.799C2.39999 4.73615 2.4239 4.68108 2.47228 4.63269L2.83262 4.2717C2.88099 4.22387 2.93605 4.19995 2.99888 4.19995C3.06116 4.19995 3.11677 4.22387 3.16459 4.2717L5.99999 7.10786L8.8354 4.2717C8.88322 4.22387 8.93883 4.19995 9.00166 4.19995C9.06394 4.19995 9.11955 4.22387 9.16737 4.2717L9.52826 4.63269C9.57608 4.68108 9.59999 4.73615 9.59999 4.799Z"
                          fill="white"
                        />
                      </svg>
                      {/* <img
                        src="/assets/Carrot.svg"
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                      ></img> */}
                      <ul
                        className="dropdown-menu"
                        style={{ minWidth: "7rem", top: "47px", left: "1px" }}
                      >
                        <li onClick={userlogout}>
                          <a className="dropdown-item">
                            {" "}
                            {isAuthenticated ? "Logout" : "Login"}
                          </a>
                        </li>
                      </ul>
                    </>
                  ) : (
                    ""
                  )}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default BenefitsNav;
