import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import SunnySdkPage from "./Components/SunnySdk";
import LandingPage from "./Pages/LandingPage";
import HomePage from "./Pages/HomePage";
import { useAuth0 } from "@auth0/auth0-react";
import { PrivateRoute } from "./PrivateRoutes";
import { UnAuthenticatedRoutes } from "./UnAuthenticatedRoutes";
import TermsOfServices from "./Components/TermsOfService";
import BenefitsFocusHome from "./Pages/BenefitsFocusHome";
import {  redirectOnLandingPageBasedOnHost, redirectOnHomePageBasedOnHost } from "./utils/Helpers/Functions";

function App() {
  const { isAuthenticated, isLoading } = useAuth0()
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
        }
      >
          {redirectOnHomePageBasedOnHost()}
      </Route>
      <Route
        element={
          <UnAuthenticatedRoutes
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
        }
      >
            {redirectOnLandingPageBasedOnHost()}
      </Route>
      <Route path="/sunnysdk" element={<SunnySdkPage />} />
      <Route path="/tos" element={<TermsOfServices />} />
    </Routes>
  );
}

export default App;
