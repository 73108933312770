import React from "react";

const BenefitsAccount = () => {
  return (
    <div>
      <section className="benefitAccoutSection">
        <div className="container tab-pane my-5 benefitAccoutSectionWrapper">
         
            <ul
              className="nav nav-tabs"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item fw-bold">
                <a
                  className="nav-link active"
                  id="benefits-tab"
                  data-toggle="tab"
                  href="#benefits"
                  role="tab"
                  aria-controls="benefits"
                  aria-selected="true"
                >
                  Your Benefits
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="accounts-tab"
                  data-toggle="tab"
                  href="#accounts"
                  role="tab"
                  aria-controls="accounts"
                  aria-selected="false"
                >
                  Your Accounts &nbsp;
                  <span className="newFeature">New!</span>
                </a>
              </li>
            </ul>

            <div className="second-content">
              <p className="me-3 view">Viewing</p>
              <div className="dropdown current">
                <p
                  className="dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Current{" "}
                  <span className="caret">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.1994 9.59809C19.1994 9.72268 19.1516 9.83392 19.0559 9.92959L12.3319 16.6554C12.2352 16.7522 12.1251 16.8 11.9994 16.8C11.8748 16.8 11.7636 16.7522 11.668 16.6554L4.94397 9.92959C4.84721 9.83392 4.79939 9.72268 4.79939 9.59809C4.79939 9.47238 4.84721 9.36225 4.94397 9.26547L5.66464 8.5435C5.76139 8.44783 5.8715 8.39999 5.99717 8.39999C6.12173 8.39999 6.23294 8.44783 6.32859 8.5435L11.9994 14.2158L17.6702 8.5435C17.7658 8.44783 17.8771 8.39999 18.0027 8.39999C18.1273 8.39999 18.2385 8.44783 18.3341 8.5435L19.0559 9.26547C19.1516 9.36225 19.1994 9.47238 19.1994 9.59809Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </p>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" href="#">
                    Action 1
                  </a>
                  <a className="dropdown-item" href="#">
                    Action 2
                  </a>
                  <a className="dropdown-item" href="#">
                    Action 3
                  </a>
                </div>
              </div>
            </div>
          </div>
    
      </section>
    </div>
  );
};

export default BenefitsAccount;
