import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const PrivateRoute = (props:any) => {
    const {  isLoading,isAuthenticated } = useAuth0();
    if (isLoading) {
        return <div>Loading...</div>
    }
    return isAuthenticated ? <Outlet /> : <Navigate to="/"/>;
};




