import React from "react";

import { useAuth0 } from "@auth0/auth0-react";

import Navbar from "../Components/Navbar";
import Header from "../Components/Header";
import Wire_content from "../Components/Wire_content";

const LandingPage = () => {
  const { loginWithRedirect } = useAuth0();

  const signup = () => {
    loginWithRedirect();
  };

  return (
    <div className="mb-4">
      <Navbar
        handleSignInOrSignOut={signup}
        marginRight={44}
        userWelcome={"Log In"}
        signUpOrSignIn={"Log In"}
        route={"/"}
        buttonText={""}
        isHeliosIframeOpen={null}
        setToggle={function (value: React.SetStateAction<boolean>): void {
          throw new Error("Function not implemented.");
        }}
      />

      <Header
        heading={"We put you first.<br/> Get started now."}
        para={
          "Annual enrollment begins June 1. Be sure and talk to your agent to discover the best plan for you and to take advantage of your benefits."
        }
        flex_direction={"flex-row-reverse"}
        image={"/assets/wire_hero_family.png"}
        text={"Sign in to view your rewards"}
        handleSignInOrSignOut={signup}
        isHeliosIframeOpen={undefined}
      />
      <Wire_content
        heading={"We’ve got our members backs. And ears. And eyes. And…"}
        para={
          "Depending on your plan benefits, you have a variety of ways to access your plan"
        }
        isHeliosIframeOpen={false}
      />
    </div>
  );
};

export default LandingPage;
